.App {
  text-align: center;
  background-image: linear-gradient(to right top, #214751, #071c22);
}

.slick-next {
  right: -15px !important;
}

.slick-prev {
  left: -15px !important;
}

.list {
  background-color: #1b3942;
  justify-content:center;
  padding:10%;
  display:flex;
  flex-wrap: wrap;
  overflow-x: scroll;
  overscroll-behavior: contain;
  scrollbar-width: none;
  -ms-overflow-style: none;
  /* momentum scroll on iOS */
  -webkit-overflow-scrolling: touch;
  /* disable vertical scroll for touch */
  /*touch-action: pan-x;*/
  /* scroll snapping */
  scroll-snap-type: x mandatory;
}

.list::-webkit-scrollbar {
  display: none;
}


.indicatorsList,
.list {
  list-style: none;
  padding: 0;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.item {
  max-height: 70VH;
  width: 100%;
  flex-shrink: 0;

  /* scroll snapping */
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

html { /* body won't work ¯\_(ツ)_/¯ */
  scroll-snap-type: y mandatory;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.4);
}

.slick-active {
  color:#ffffff;
}

.slick-dots li button:before {
  color:#ffffff !important;
  font-size: 12px !important;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.pin-icon{
   color:#d67729;
}

.pin-text {
  color:#d67729;
  font-family: "text";
  font-size: 1.2rem;
  text-shadow: 2px 2px black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.gform { /* instrukcije za google form */
  width: 100%;
  height: 83vh;
  border: none;
  margin: 20px auto;
  
}