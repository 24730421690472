@font-face {
  font-family: "main";
  src: local("UnicaOne-Regular"),
       url("/public/fonts/UnicaOne-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "text";
  src: local("RedditSansCondensed"),
       url("/public/fonts/RedditSansCondensed.ttf") format("truetype");
}

.main { 
  font-family: main;
  color: #ffffff;
  font-size: calc(30px + 4vmin); 
  }

li {
    list-style: disc; 
    padding-inline-start: 2VH; 
    text-align: left; 
    font-family: "text"; /* Taken from App.css*/
    font-size: 18px;
    line-height: 160%;
    }

p {
    text-align: left;
    font-family: "text";
    font-size: 18px;
    line-height: 160%;
    
    }

h1 {
    font-weight: 400;
    font-family: "main"; /* Taken from App.css*/
    color: white;
    }

h2 {
  font-size: 24px;
  font-weight:bold;
  text-align: center;
  font-family: "text";
    }
    
h3{
  font-family:"text";
  font-weight:bold;
  font-size: 20px;
  text-align: left;
    }

.white_text{
  color: white;
  text-shadow: 1px 1px 1px #214751;
}

.logo {
  width: auto;
  max-height: 7vh; 
  aspect-ratio: 400 / 100; 
  object-fit: contain; 
}


.conclusion { /*<div className="conclusion"><p className='conclusion_content'>XXX</p></div>*/
  background-color: #f8c093;
  padding: 1.2rem;
  margin-top: 40px;
  border-radius: 20px;
  position: relative;
}

.conclusion_content {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #094658;
}
.conclusion::after {
  content: "";
  background-color: #fcf9f3;
  width: 80px;
  height: 80px;
  position: absolute;
  border-radius: 50%;
  background-size: 75%;
  background-image: url('Images/lightbulb.png');
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0px 0px 10px #f8c093;
  top: -40px;      /* Position it 40px above the top edge */
  left: 50%;       /* Position horizontally in the middle */
  transform: translateX(-50%); /* Horizontally center the icon */
}

.carousel{
  padding-bottom: 20px;
}

.table-of-questions {
  display:block;
  position:static;
  min-width: 300px;
  top: 0;
  padding-right: 20px;
}

.table-of-questions a {
  color: #d67729;
  font-size: 19px;
  line-height: 130%;

}

.google-calendar {
  transform: scale(2);
  color: white;
}
/*HOME --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/


/* Linkovi */

/*Rules for phones vs monitors------------------------------------------------------------------------------------------------------------------------------------------------------------*/

/* Special rules for phones*/
@media (max-width: 768px) {

  .google-map{
    height: 35VH !important;
  }
  .subtitle {
    font-size: 1rem;
    max-width: 90%; /* Manja širina na telefonima */
}

.button-wrapper {
    margin-bottom: 10vh; /* Prilagodjava razmak na manjim ekranima */
}
}

/*Normal rules for Phones*/

.column-home { /* explains home page*/
  margin-top: 10vH;
  display: flex;
  flex-direction: column;
  max-width: 90%;
  gap: 20vH;
}

.links-wrapper {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  bottom: 20px;
}

.horizontal-padding-for-phone {
  padding-right: 5vw;
  padding-left: 5vw;
}

.hide-element-on-small { /*This element will not be loaded - className="hide-element-on-small" */
  visibility: hidden;
  display: none; 
}

.hide-element-on-big {/*This element will be shown on phone only - className="hide-element-on-big" */
  visibility: visible;
  display:inherit; 
}

.common-questions-table-behaviour{
  display: flex;
  flex-direction: column;
}

.case_study_image{
  width: 90%;
  max-width:500px;
  border-radius: 25px;
}

.blogimage{
  justify-content: center;
  align-content: center;
  max-width: 100%;
  border-radius: 25px;
}

.blogimage-square-containter{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  align-items: center;
}

.blogimage-square{
  justify-content: center;
  align-content: center;
  max-width: 70%;
  border-radius: 25px;
}
.blogimage-square-left{
  justify-content: center;
  align-content: center;
  max-width: 70%;
  border-radius: 25px;
}

.service-block {
  width: 80%;
  margin: 10px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  background-color: #1e1e1e;
  text-align: center;
}

.service-block img {
  width: 100%; /* Slika zauzima punu širinu roditeljskog elementa */
  aspect-ratio: 1 / 1; /* Omogućava kvadratni oblik */
  object-fit: cover;
  border-radius: 10px;
}

.service-block p {
  color: white;
  font-size: 20px;
  margin-top: 10px;
  text-align: center;
}

.header-container {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px; /* Ovo treba da radi sa flexbox */
  padding: 10px;
}

.header-button {

  height: 50px;
  min-width: 100px !important;
  max-width: 150px;
  flex-shrink: 0;
}

.header-button-text {
  color: #d67729;
  font-family: 'main', sans-serif;
  font-size: 20px;
  margin: 0;
}


/* Special rules for monitors--------------------------------*/
@media (min-width: 768px) { 

  .horizontal-padding-for-phone {
    padding-right: 0px;
    padding-left: 0px;
  }

  .hide-element-on-small {/*This element will be shown on monitors only*/
    visibility: visible;
    display:inherit; 
  }

  .hide-element-on-big {/*This element will be shown on phone only*/
    display: none !important;  
  }
  .common-questions-table-behaviour{
    display: flex;
    flex-direction: row;
  }

  .blogimage{
    justify-content: center;
    align-content: center;
    max-width: 70%;
    border-radius: 25px;
  }
  .blogimage-square-containter{
    flex-direction: row;
  }
  
  .blogimage-square{
    justify-content: center;
    align-content: center;
    max-width: 30%;
    border-radius: 25px;
    padding-left: 10px;
  }
  .blogimage-square-left{
    justify-content: center;
    align-content: center;
    max-width: 30%;
    border-radius: 25px;
    padding-right: 10px;
  }

  .column-home { /* explains home page*/
    margin-top: 10vH;
    display: flex;
    flex-direction: column;
    max-width: 90%;
    gap: 20vH;
  }
  .links-wrapper {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    bottom: 20px;
    right: 4vW;
  }
  .service-block {
    width: 17%;
    margin: 5px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 10px;
    background-color: #1e1e1e;
    text-align: center;
  }
  
  .service-block img {
    width: 100%;
    aspect-ratio: 1 / 1; /* Omogućava kvadratni oblik */
    object-fit: cover;
    border-radius: 10px;
  }
  
  .service-block p {
    color: white;
    font-size: 20px;
    margin-top: 10px;
    text-align: center;
  }
}